import React from 'react';
import Image from '../../utils/OptimizedImage';
import Icon from '../../styles/atoms/icons';

const FeaturedImageBlock = ({ featuredImage, imageCredit }) => (
  <div className="featuredImage-wrapper">
    {featuredImage &&
      (featuredImage?.gatsbyImageData ? (
        <Image
          alt={featuredImage.alt}
          image={featuredImage.gatsbyImageData}
          title={featuredImage?.title}
        />
      ) : (
        <Image
          alt={featuredImage.alt}
          title={featuredImage?.title}
          src={featuredImage.url}
        />
      ))}
    {featuredImage && imageCredit && (
      <div className="credit-wrapper">
        <Icon className="icon" id="cameraIcon" isImage />
        <p>
          <span>Image Credit: </span>
          {imageCredit}
        </p>
      </div>
    )}
  </div>
);

export default FeaturedImageBlock;
